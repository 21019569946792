import {
  AG_GRID_CELL_COMPONENTS,
  DEFAULT_GRID_OPTIONS,
} from './ag-grid-table.config';
import { CUSTOM_EVENTS, CommonUtil } from '@core';
import {
  ColDef,
  Column,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from 'ag-grid-community';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  inject,
} from '@angular/core';
import {
  IsRowSelectable,
  ProcessUnpinnedColumnsParams,
} from 'ag-grid-enterprise';
import { Subject, debounceTime, fromEvent, merge, noop, takeUntil } from 'rxjs';

import { AG_GRID_LICENSE_TOKEN } from './license-util';
import { AgGridAngular } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { icons } from 'src/app/core/constants/icons.constants';

@Component({
  selector: 'lib-ag-grid-table',
  standalone: true,
  imports: [CommonModule, AgGridAngular],
  templateUrl: './ag-grid-table.component.html',
  styleUrls: ['./ag-grid-table.component.less'],
})
export class AgGridTableComponent implements OnDestroy {
  @Input() customStyle = {
    width: '100%',
  };
  @Input() className: string = 'ag-theme-alpine';
  @Input() isRowSelectable: IsRowSelectable<any>;
  @Input() rowData: any[] = [];
  @Input() set columnDefs(colDefs: ColDef[]) {
    const prevDef = this.columnDefs;
    this._columnDefs = colDefs;
    if (colDefs?.length && CommonUtil.deepEqual(colDefs, prevDef)) {
      this.gridApi?.sizeColumnsToFit();
    }
  }
  get columnDefs() {
    return this._columnDefs;
  }
  @Input() rowDragMultiRow: boolean = true;
  @Input() rowDragManaged: boolean = false;
  @Input() animateRows: boolean = false;
  @Input() suppressMoveWhenRowDragging: boolean = true;
  @Input() icons: {
    [key: string]: ((...args: any[]) => any) | string;
  } = icons;
  @Input() rowHeight: number = 50;
  @Input() maxRowCountView: number = 8;
  @Input() tooltipShowDelay: number = 0;
  @Input() groupDisplayType: string = 'singleColumn';
  @Input() isTreeData: boolean = false;
  @Input() autoGroupColumnDef: ColDef;
  @Input() set gridOptions(options) {
    this._gridOptions = {
      ...DEFAULT_GRID_OPTIONS,
      context: {
        ...(options.context ?? {}),
        items: this.rowData,
      },
      ...options,
    };
  }

  get gridOptions(): GridOptions {
    this._gridOptions = this._gridOptions ?? DEFAULT_GRID_OPTIONS;
    if (this.isTreeData) {
      this._gridOptions = {
        ...this._gridOptions,
        treeData: true,
        getDataPath: this.getDataPath, // 'dataPath' is an array representing the path
        autoGroupColumnDef: this.autoGroupColumnDef,
      };
    }
    return this._gridOptions;
  }
  @Input() defaultColDef: ColDef = {};
  @Input() groupDefaultExpanded = -1;
  @Input() postSortRows: (param) => void = noop;
  @Input() context;
  @Input() rowClassRules: any = {};
  @Input() treeData: boolean;
  @Input() isChildrenSelection: boolean = true;
  @Input() getDataPath;
  @Input() grandTotalRow: 'top' | 'bottom';
  @Input() suppressStickyTotalRow: boolean = false;
  @Input() processUnpinnedColumns: (
    params: ProcessUnpinnedColumnsParams<any>
  ) => Column[];
  @Input() pinnedBottomRowData: any[];
  @Output() selectedIdsChange = new EventEmitter<number[]>();
  @Output() toggleParent = new EventEmitter<boolean>();
  @Output() gridReady = new EventEmitter();
  @Output() sortChanged = new EventEmitter();
  @Output() selectedParents = new EventEmitter<any[]>();
  @Output() rowDragEnter = new EventEmitter();
  @Output() rowDragEnd = new EventEmitter();
  @Output() rowSelected = new EventEmitter();
  @Output() rowSelectedHasGroup = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  public license = void inject(AG_GRID_LICENSE_TOKEN);
  components = AG_GRID_CELL_COMPONENTS;

  private _gridOptions: GridOptions;
  public gridApi!: GridApi;
  private _columnDefs: ColDef[];
  private unSub$ = new Subject<void>();

  onGridReady(params: GridReadyEvent) {
    try {
      this.gridApi = params.api;
      this.gridReady.emit(params.api);
      this.gridApi.addEventListener('rowGroupOpened', () => {
        setTimeout(() => {
          this.gridApi.onRowHeightChanged();
        }, 0);
      });
      this.setupResizeListener();
    } catch (error) {
      console.error('Error initializing grid API', error);
    }
  }

  private setupResizeListener() {
    const windowResize$ = fromEvent(window, 'resize');
    const sideNavToogle$ = fromEvent(
      window,
      CUSTOM_EVENTS.global.sideNavToggle
    );
    merge(windowResize$, sideNavToogle$)
      .pipe(debounceTime(200), takeUntil(this.unSub$))
      .subscribe(() => {
        this.resizeGrid();
      });
  }

  /**
   *  Emit the selected IDs when the selection changes
   */
  onSelectionChanged(): void {
    const selectedRows = this.gridApi
      .getSelectedRows()
      .filter((row) => !row.visual_only);
    const selectedIds = selectedRows.map((row) => row.id || row.asset_id);
    this.selectedIdsChange.emit(selectedIds);
    if (!this.gridOptions.treeData) {
      return;
    }
    // Extract paths of all selected nodes
    const selectedPaths = selectedRows.map(
      (node: any) => `/${node.data_path.join('/')}/`
    );

    // Filter to only subtree roots
    const subtreeRoots = selectedRows.filter((node: any) => {
      const nodePath = `/${node.data_path.join('/')}/`;
      return !selectedPaths.some(
        (path: string) => path !== nodePath && nodePath.startsWith(path)
      );
    });
    this.selectedParents.emit(subtreeRoots);
  }

  onRowSelected(event: any) {
    const node = event.node;
    // Only show the message for parent nodes clicked by the user
    if (this.gridOptions.treeData) {
      if (
        event.source === 'checkboxSelected' &&
        node.isSelected() &&
        node.childrenAfterGroup?.length > 0
      ) {
        this.rowSelectedHasGroup.emit(node);
      }
      if (this.isChildrenSelection) {
        if (node.isSelected()) {
          this.selectChildren(node, true);
        } else {
          // Prevent deselection of children if parent is still selected
          if (node.parent && node.parent.isSelected()) {
            node.setSelected(true, false); // Re-select the node
          } else {
            this.selectChildren(node, false);
          }
        }
      }
    }
    this.onSelectionChanged();
    this.gridApi.refreshHeader();
    this.rowSelected.emit(event);
  }

  selectChildren(node: any, isSelected: boolean) {
    node?.childrenAfterGroup?.forEach((child: any) => {
      child.setSelected(isSelected, false); // Propagate selection
      if (child.childrenAfterGroup.length) {
        this.selectChildren(child, isSelected); // Recursive selection
      }
    });
  }

  gridHeight(): number {
    let HEADER_HEIGHT = 43;
    let BUFFER_HEIGHT = 3;

    if (this._gridOptions?.headerHeight) {
      HEADER_HEIGHT += 20;
    }
    // Adjust buffer if "Actions" column exists
    if (
      this.rowData.length === 1 &&
      this._columnDefs.some((col) => col.headerName === 'Actions')
    ) {
      BUFFER_HEIGHT += 60;
    }

    /** Count Only Visible Rows (Ignoring Collapsed Ones) */
    let visibleRowCount = this.grandTotalRow ? 0 : this.rowData.length;
    if (this.grandTotalRow) {
      this.gridApi?.forEachNode((node) => {
        if (node.displayed) {
          visibleRowCount++;
        }
      });
    }

    // If no rows are visible, return header height only
    if (visibleRowCount === 0) {
      return HEADER_HEIGHT;
    }

    // Add height for grand total row if applicable
    if (this.grandTotalRow) {
      HEADER_HEIGHT += 65;
    }

    if (visibleRowCount > 7) {
      return this.maxRowCountView * this.rowHeight + HEADER_HEIGHT;
    }

    return visibleRowCount * this.rowHeight + HEADER_HEIGHT + BUFFER_HEIGHT;
  }

  resizeGrid() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
      this.gridHeight();
    }
  }

  ngOnDestroy() {
    this.unSub$.next();
    this.unSub$.complete();
  }
}

import { HighlightTextPipe, IagLinkCellParam } from '@core';

import { AsyncPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'lib-ag-link',
  standalone: true,
  imports: [RouterLink, HighlightTextPipe, AsyncPipe],
  templateUrl: './ag-link.component.html',
})
export class AgLinkComponent implements ICellRendererAngularComp {
  routerLink: string[];
  value: string;
  className: string = '';
  searchText$;
  agInit(params: IagLinkCellParam): void {
    this.searchText$ = params.context?.searchText$ ?? new BehaviorSubject('');
    this.routerLink = params.getUrl?.(params);
    this.value = params.valueFormatted ?? params.value;
    this.className = params.className ?? '';
  }

  refresh(): boolean {
    return false;
  }
}
